import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { PaymentPlanFields } from './components/PaymentPlanFields'
import { DiscountFields } from './components/DiscountFields'

import type { FormAddTuitionProps } from '../../types'
import { installmentDueDateRule } from '../../constants'

import * as Styled from './styles'

export type InstallmentFormProps = {
  form: UseFormReturn<FormAddTuitionProps>
  referenceYear: number
  renderDiscountBtn?: (disabled: boolean, onClick: () => void) => JSX.Element
}

export const InstallmentForm = ({
  form,
  referenceYear,
  renderDiscountBtn,
}: InstallmentFormProps) => {
  const { trigger, getValues, watch } = form

  watch([
    'duration_months',
    'due_day',
    'custom_monthly_amount',
    'installment_due_date_rule',
    'due_date_month',
  ])

  const formValues = getValues()
  const { duration_months, due_date_month } = formValues

  const durationMonths = duration_months ? +duration_months : 0
  const hasMultipleInstallments = durationMonths > 1
  const installmentTotalAmount = formValues.custom_monthly_amount * durationMonths

  const isUsingAWorkingDateRule =
    formValues.installment_due_date_rule === installmentDueDateRule.WORKING_DAY
  const enableDueDayField = formValues.installment_due_date_rule !== null

  const validateDueDate = (value: number) => {
    if (isUsingAWorkingDateRule) {
      const MAX_WORKING_DAY = 25
      return value > 0 && value <= MAX_WORKING_DAY
        ? true
        : 'O dia útil precisa ser menor ou igual a 25'
    }

    const MAX_FIXED_DAY = 31
    return value && value > 0 && value <= MAX_FIXED_DAY
      ? true
      : 'O dia fixo precisa ser menor ou igual a 31'
  }

  useEffect(() => {
    if (duration_months && due_date_month) {
      trigger('duration_months')
    }
  }, [duration_months, due_date_month])

  return (
    <Styled.Wrapper>
      <PaymentPlanFields
        form={form}
        validateDueDate={validateDueDate}
        installmentTotalAmount={installmentTotalAmount}
        hasMultipleInstallments={hasMultipleInstallments}
        enableDueDayField={enableDueDayField}
        referenceYear={referenceYear}
      />
      <DiscountFields form={form} renderDiscountBtn={renderDiscountBtn} />
    </Styled.Wrapper>
  )
}
