import { useState, useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import {
  filterContractsForm,
  filterContractsSchema,
} from '@monorepo/students/models/filterContractsSchema'
import { TableFilter } from './types'
import { FilterChip } from '@monorepo/students/pages/Student/components/ContractsSection/components/FilterChip'
import { ContractsFiltersType } from '../useQueryParamFilters/types'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventPageName } from '@monorepo/students/models/EventPageName.enum'
import { EventIdentifierName } from '@monorepo/students/models/EventIdentifierName.enum'

type useFilterContractsType = {
  clearFilter: () => void
  referenceYearsOptions: string[]
  updateFilter: (data: ContractsFiltersType) => void
}

export const useFilterContracts = ({
  updateFilter,
  referenceYearsOptions,
  clearFilter,
}: useFilterContractsType) => {
  const [isFilterDialogOpen, toggleIsFilterDialogOpen] = useState(false)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const emptyForm: filterContractsForm = {
    reference_years: referenceYearsOptions.map(year => ({
      label: year,
      value: year,
      selected: false,
    })),
  }

  const form = useForm<filterContractsForm>({
    mode: 'all',
    defaultValues: emptyForm,
    resolver: zodResolver(filterContractsSchema),
  })

  const { control, setValue } = form

  const handleOpenFilterDialog = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.STUDENT_PAGE,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        pageName: EventPageName.STUDENT_PAGE,
        identifierName: EventIdentifierName.CONTRACTS_FILTER,
      })
    }

    toggleIsFilterDialogOpen(true)
  }

  const handleCloseFilterDialog = () => {
    toggleIsFilterDialogOpen(false)
  }

  const handleClearFilter = () => {
    setValue(
      'reference_years',
      form.getValues().reference_years?.map(year => ({
        ...year,
        selected: false,
      }))
    )

    clearFilter()
  }

  const handleRemoveFilter = (filter: TableFilter) => {
    const convertedFilter = filter.value

    const filterToRemove = form
      .getValues()
      .reference_years?.find(year => year.value === convertedFilter)

    if (filterToRemove) {
      setValue(
        'reference_years',
        form.getValues().reference_years?.map(year => {
          if (year.value === convertedFilter) {
            return {
              ...year,
              selected: false,
            }
          }
          return year
        })
      )
    }

    const filtersToUpdate = {
      reference_years: form?.getValues()?.reference_years
        ? form
            .getValues()
            .reference_years?.filter(year => year.selected)
            ?.map(year => year.value) ?? []
        : [],
    }

    updateFilter(filtersToUpdate)
  }

  const filtersArray = useMemo<TableFilter[]>(() => {
    const referenceYearsFilters =
      form
        .getValues()
        .reference_years?.filter(year => year.selected)
        .map(year => ({
          text: year.label,
          value: year.value,
        })) ?? []

    return referenceYearsFilters
  }, [form.getValues().reference_years])

  const onSubmitFilters: SubmitHandler<filterContractsForm> = ({ reference_years }) => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.STUDENT_PAGE,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        pageName: EventPageName.STUDENT_PAGE,
        identifierName: EventIdentifierName.TO_SAVE_CONTRACTS_FILTER,
      })
    }

    updateFilter({
      reference_years:
        reference_years?.filter(year => year.selected)?.map(year => year.value) ?? [],
    })
    toggleIsFilterDialogOpen(false)
  }

  const filtersGroup = [
    {
      id: 0,
      title: 'Ano de referência',
      component: <FilterChip control={control} filterType="reference_years" />,
    },
  ]

  return {
    isFilterDialogOpen,
    referenceYearsOptions,
    handleOpenFilterDialog,
    handleCloseFilterDialog,
    handleClearFilter,
    handleRemoveFilter,
    onSubmitFilters,
    filtersArray,
    filtersGroup,
    form,
    setValue,
  }
}
