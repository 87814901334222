import styled from 'styled-components'

export const Container = styled.header<{ scrolled: boolean }>`
  display: flex;
  position: fixed;
  align-items: center;

  height: 60px;

  top: 0;
  gap: ${({ theme }) => theme.gravity.spacing[4]};
  padding: ${({ theme }) => theme.gravity.spacing[4]} 67px;
  z-index: 98;

  background-color: ${({ theme }) => theme.gravity.colors['colors-background-neutral-1']};

  border-bottom: ${props =>
    props.scrolled ? `1px solid ${props.theme.gravity.colors['colors-border-neutral-3']}` : 'none'};

  width: 100%;
`

export const SubContainer = styled.div<{ scrolled: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.gravity.spacing[4]};
  align-items: center;

  opacity: ${props => (props.scrolled ? 1 : 0)};
  transform: ${props => (props.scrolled ? 'translateY(0)' : 'translateY(20px)')};
  transition: opacity 0.3s ease, transform 0.3s ease;
`
