import { forwardRef, useState, useEffect } from 'react'
import { Button } from '@gravity/button'
import { Table } from '@gravity/table'
import { useToast } from '@gravity/toast'
import { mdiPlus } from '@mdi/js'
import Icon from '@mdi/react'

import { ContractCancellationReason } from '@/shared/interfaces'
import { useContractRedirect } from './hooks/useContractRedirect'
import { StudentContract } from '@monorepo/students/services/students/types'
import { useOrdering } from '@/shared/hooks/useOrdering'
import type { SortOrder } from '@/shared/hooks/useOrdering/types'
import { useQueryParamFilters } from '@monorepo/students/hooks/useQueryParamFilters'
import { useFilterContracts } from '@monorepo/students/hooks/useFilterContracts'
import { useSortContracts } from '@monorepo/students/hooks/useSortContracts'

import { queryClient } from '@/shared/contexts/ReactQuery'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { useMutationCancelProposal } from '@monorepo/students/hooks/mutations/contracts'
import { useAcceptPaymentPlan } from '@monorepo/students/hooks/queries/contracts'

import { FiltersDialog } from '@/shared/components/FiltersDialog'
import { ContractsSectionPlaceholder } from './ContractsSectionPlaceholder'
import { SectionContainer } from '../SectionContainer'
import { SectionErrorState } from '../SectionErrorState'
import { ContractActions } from './ContractActions'
import { CancelProposalDialog } from './CancelProposalDialog'
import { AddTuitionDialog } from './AddTuitionDialog'
import { useAddTuitionToOneCampaignStudent } from './hooks/useAddTuitionToOneCampaignStudent'
import { AcceptPaymentPlanDialog } from './AcceptPaymentPlanDialog'

import { getDebtStatus } from './utils/getDebtStatus'

import { COLUMN_HEADERS, CONTRACTS_STATUS } from './constants'

import type { AddTuitionToCampaignState, Columns } from './types'

import { Container } from './styles'

type ContractsSectionProps = {
  data?: {
    contracts: StudentContract[]
    reference_years: string[]
  }
  guardianId: string
  hasError: boolean
  id: string
  isLoading: boolean
  onRetry: () => void
  studentId: string
  studentName: string
}

export const ContractsSection = forwardRef<HTMLElement, ContractsSectionProps>(
  ({ id, guardianId, studentId, studentName, data, isLoading, hasError, onRetry }, ref) => {
    const [enrollmentId, setEnrollmentId] = useState<string | undefined>(undefined)
    const { toast } = useToast()
    const { school } = useSelectedSchool()
    const { ordering, updateOrdering } = useOrdering<Columns>({
      sortBy: 'product.name',
      sortOrder: 'ASC',
    })

    const { sortedContracts } = useSortContracts(data?.contracts ?? [], ordering)

    const defaultFilters = [
      new Date().getFullYear().toString(),
      (new Date().getFullYear() + 1).toString(),
    ]

    const { filtersParams, updateFiltersParams, clearParams } = useQueryParamFilters(defaultFilters)

    const referenceYearsOptions = data?.reference_years ?? []

    const {
      filtersArray,
      filtersGroup,
      form: filterForm,
      handleClearFilter,
      handleCloseFilterDialog,
      handleOpenFilterDialog,
      handleRemoveFilter,
      isFilterDialogOpen,
      onSubmitFilters,
      setValue,
    } = useFilterContracts({
      updateFilter: updateFiltersParams,
      referenceYearsOptions,
      clearFilter: clearParams,
    })

    const filteredAndOrderedContracts = sortedContracts.filter(contract => {
      if (filtersParams.reference_years.length === 0) {
        return true
      } else {
        return filtersParams.reference_years.includes(contract.reference_year)
      }
    })

    const [addTuitionDialogState, setAddTuitionDialogState] = useState<AddTuitionToCampaignState>({
      campaignId: '',
      enrollmentId: '',
      studentName: '',
      openDialog: false,
      referenceYear: 0,
    })

    const {
      isLoadingAddTuitionMutation,
      handleSubmitAddTuitionToProposal,
      form,
      handleCloseDialogForm,
    } = useAddTuitionToOneCampaignStudent({
      campaignId: addTuitionDialogState.campaignId ?? '',
      school,
      setAddTuitionDialogState,
    })

    const {
      mutate,
      isLoading: isLoadingAcceptPaymentPlan,
      isError: isErrorAcceptPaymentPlan,
      isSuccess: isSuccessAcceptPaymentPlan,
      data: acceptPaymentPlanData,
      reset: acceptPaymentPlanReset,
    } = useAcceptPaymentPlan()

    const {
      mutateAsync: mutateOnCancelProposal,
      isLoading: isLoadingUpdateAccess,
    } = useMutationCancelProposal({
      onSuccess() {
        setEnrollmentId(undefined)
        toast({
          title: 'Proposta cancelada com sucesso',
          type: 'success',
        })

        queryClient.invalidateQueries(['students', studentId, 'contracts'])
      },
      onError: () => {
        toast({
          title: 'Erro ao cancelar proposta, tente novamente mais tarde',
          type: 'error',
        })
      },
    })

    const {
      handleAddNewContract,
      handleRedirectToEditContract,
      handleRedirectToInvoice,
    } = useContractRedirect()

    const handleAddTuition = ({
      campaignId,
      referenceYear,
      enrollmentId,
    }: {
      campaignId: string
      enrollmentId: string
      referenceYear: string
    }) => {
      setAddTuitionDialogState({
        studentName: '',
        enrollmentId,
        campaignId,
        referenceYear: Number(referenceYear),
        openDialog: true,
      })
    }

    const handleRemoveStudentFromCampaign = ({ enrollmentId }: { enrollmentId: string }) => {
      if (school?.id) {
        mutateOnCancelProposal({
          enrollmentId: enrollmentId,
          schoolId: school.id,
          params: {
            cancellation_description: 'PROPOSAL_CANCELED',
            cancellation_reason: ContractCancellationReason.OTHER,
          },
        })
      }
    }

    const handleCloseAcceptPaymentPlanDialog = () => {
      acceptPaymentPlanReset()

      queryClient.invalidateQueries(['students', studentId, 'contracts'])
    }

    if (hasError && !!onRetry) {
      return <SectionErrorState onRetry={onRetry} />
    }

    useEffect(() => {
      setValue(
        'reference_years',
        referenceYearsOptions.map(year => ({
          label: year,
          value: year,
          selected: filtersParams.reference_years.includes(year),
        }))
      )
    }, [referenceYearsOptions, filtersParams, setValue])

    return (
      <SectionContainer
        id={id}
        ref={ref}
        title="Contratos"
        action={
          <Button
            onClick={() => handleAddNewContract(guardianId)}
            variant="outline"
            iconStart={<Icon path={mdiPlus} />}
          >
            Adicionar contrato
          </Button>
        }
      >
        <Container>
          <Table.Root>
            <Table.Filtering
              filters={filtersArray}
              onClickFilterButton={() => handleOpenFilterDialog()}
              onRemoveFilter={filter => handleRemoveFilter(filter)}
            />
            <Table.Head>
              {COLUMN_HEADERS.map(({ label, name, sortable }) => (
                <Table.HeaderCell
                  key={name}
                  name={name}
                  sortable={!isLoading && !!data?.contracts && sortable}
                  onSort={(sortBy, sortOrder) => {
                    updateOrdering({
                      sortBy: sortBy as Columns,
                      sortOrder: sortOrder as SortOrder,
                    })
                  }}
                  sortOrder={
                    ordering.sortBy === name && ordering.sortOrder
                      ? (ordering.sortOrder.toLowerCase() as Lowercase<SortOrder>)
                      : undefined
                  }
                >
                  {label}
                </Table.HeaderCell>
              ))}
            </Table.Head>

            <Table.Body>
              {isLoading && <ContractsSectionPlaceholder />}
              {!isLoading && !!data?.contracts && (
                <>
                  {filteredAndOrderedContracts.map(contract => (
                    <Table.Row
                      onClick={event => {
                        event.preventDefault()

                        handleRedirectToInvoice({
                          guardianId: contract.guardian?.id ?? '',
                          referenceYear: contract.reference_year,
                          productId: contract.product?.id ?? '',
                          studentId,
                        })
                      }}
                      key={contract.id}
                      data-testid="table-row"
                    >
                      <Table.TextCell>{contract.product?.name ?? '-'}</Table.TextCell>
                      <Table.TextCell>{contract.reference_year}</Table.TextCell>
                      <Table.TextCell>{contract.guardian?.name ?? ''}</Table.TextCell>
                      <Table.TextCell>{CONTRACTS_STATUS[contract.status] ?? '-'}</Table.TextCell>
                      {contract?.debt_status ? (
                        <Table.BadgeCell
                          badgeColor={getDebtStatus(contract.debt_status).color}
                          badgeVariant="soft"
                        >
                          {getDebtStatus(contract.debt_status).text}
                        </Table.BadgeCell>
                      ) : (
                        <Table.TextCell>-</Table.TextCell>
                      )}
                      <ContractActions
                        studentId={studentId}
                        contract={contract}
                        onRedirectToEditContract={handleRedirectToEditContract}
                        onRedirectToInvoice={handleRedirectToInvoice}
                        onOpenProposalDialog={() => setEnrollmentId(contract.id)}
                        onOpenAddTuitionDialog={handleAddTuition}
                        onAcceptPaymentPlan={contractId => mutate({ enrollmentId: contractId })}
                      />
                    </Table.Row>
                  ))}
                </>
              )}
            </Table.Body>
          </Table.Root>
          <CancelProposalDialog
            isLoading={isLoadingUpdateAccess}
            onClose={() => setEnrollmentId(undefined)}
            onConfirm={() => {
              if (enrollmentId) {
                handleRemoveStudentFromCampaign({
                  enrollmentId,
                })
              }
            }}
            open={!!enrollmentId}
          />
          <AddTuitionDialog
            open={addTuitionDialogState.openDialog && !!addTuitionDialogState.enrollmentId}
            enrollmentID={addTuitionDialogState.enrollmentId}
            onConfirm={handleSubmitAddTuitionToProposal}
            onClose={handleCloseDialogForm}
            form={form}
            isLoading={isLoadingAddTuitionMutation}
            referenceYear={addTuitionDialogState.referenceYear ?? 0}
            studentName={studentName}
          />
          <AcceptPaymentPlanDialog
            isLoading={isLoadingAcceptPaymentPlan}
            isSuccess={isSuccessAcceptPaymentPlan}
            isError={isErrorAcceptPaymentPlan}
            installment={
              !isErrorAcceptPaymentPlan && !!acceptPaymentPlanData
                ? acceptPaymentPlanData
                : undefined
            }
            onClose={handleCloseAcceptPaymentPlanDialog}
          />
        </Container>
        <FiltersDialog
          closeDialog={() => handleCloseFilterDialog()}
          filters={filtersGroup}
          form={filterForm}
          onApplyFilters={onSubmitFilters}
          resetValues={handleClearFilter}
          visible={isFilterDialogOpen}
        />
      </SectionContainer>
    )
  }
)
